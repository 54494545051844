import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import type { FeedbackResponse } from 'src/types/feedbackResponse'

interface FeedbackResponsesState {
  feedbackResponses: FeedbackResponse[];
  totalFeedbackResponses: number;
  isModalOpen: boolean;
  isLoading: boolean;
  error: null;
  selectedFeedbackResponse: FeedbackResponse;
}

const initialState: FeedbackResponsesState = {
  feedbackResponses: [],
  totalFeedbackResponses: 0,
  isModalOpen: false,
  isLoading: false,
  error: null,
  selectedFeedbackResponse: undefined,
};

const slice = createSlice({
  name: 'feedbackResponses',
  initialState,
  reducers: {
    getFeedbackResponses(state: FeedbackResponsesState, action: PayloadAction<{ feedbackResponses: any[]; totalFeedbackResponses:number }>) {
      const { feedbackResponses, totalFeedbackResponses } = action.payload;
      state.feedbackResponses = feedbackResponses;
      state.totalFeedbackResponses = totalFeedbackResponses;
    },
    createFeedbackResponse(state: FeedbackResponsesState, action: PayloadAction<{}>) {
      state.isModalOpen = false;
    },
    openModal(state: FeedbackResponsesState) {
      state.isModalOpen = true;
    },
    closeModal(state: FeedbackResponsesState) {
      state.isModalOpen = false;
    },
  }
});

export const reducer = slice.reducer;

export const openModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const createFeedbackResponse = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.createFeedbackResponse({}));
};

export const getFeedbackResponses = (startFrom: number, limit: number): AppThunk => async (dispatch) => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  const params = {
    startFrom: startFrom,
    limit: limit,
  };
  
  const response = await axios({
    method: 'get',
    url: `v2/therapist/${therapistId}/feedbackResponses`,
    params: params
  });
  if (response.data) {
    dispatch(slice.actions.getFeedbackResponses({ feedbackResponses: response.data.feedbackResponses, totalFeedbackResponses: response.data.totalFeedbackResponses }));
  } else {
    console.error("Unexpected API response format:", response);
  }
}

export const getPaginatedFeedbackResponses = (startFrom: number, limit: number, sortKey?: string, sortOrder?: string, searchTerm?: string, system?: string, type?: string,): AppThunk => async (dispatch) => {
  const therapistId = system ? system : JSON.parse(localStorage.getItem('user'))['userId'];
  const params = {
    startFrom: startFrom * (limit ? limit : 10) || 0,
    limit: limit || 10,
  };

  if (searchTerm && searchTerm.length > 0) {
    params['searchTerm'] = searchTerm;
  }

  if (sortKey && sortKey.length > 0) {
    params['sortKey'] = sortKey;
  }

  if (sortOrder && sortOrder.length > 0) {
    params['sortOrder'] = sortOrder;
  }  

  if (type && type.length > 0) {
    params["type"] = type
  }

  const response = await axios({
    method: 'get',
    url: `v2/therapist/${therapistId}/feedbackResponses`,
    params: params
  });
  if (response.data) {
    dispatch(slice.actions.getFeedbackResponses({ feedbackResponses: response.data.feedbackResponses, totalFeedbackResponses: response.data.totalFeedbackResponses }));
  } else {
    console.error("Unexpected API response format:", response);
  }
}

export default slice;
